import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';
import SearchIconOutline from '~/assets/icons/searchIconOutline';

export interface FilterInputProps
  extends Pick<
      ComponentPropsWithoutRef<'input'>,
      'value' | 'placeholder' | 'onChange' | 'autoFocus'
    >,
    Pick<ComponentPropsWithoutRef<'div'>, 'className'> {}

const FilterInput: FC<FilterInputProps> = ({ className, ...inputProps }) => {
  return (
    <div
      className={clsx(
        className,
        'flex h-[26px] w-full flex-1 items-center overflow-hidden rounded-md bg-darkMode-gray-medium/20'
      )}
    >
      <SearchIconOutline className="ml-[6px] h-4 w-4 text-darkMode-gray-light/70" />
      <input
        type="text"
        {...inputProps}
        className="h-full w-full !border-none bg-transparent text-sm font-medium text-darkMode-gray-light/70 placeholder-darkMode-gray-light/70 placeholder-shown:text-sm placeholder-shown:font-medium"
      />
    </div>
  );
};

export default FilterInput;
