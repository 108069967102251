import { useNavigate } from '@remix-run/react';
import { FC } from 'react';
import ButtonNew from '../../general/ButtonNew';
import InputFeedbackMessageBox, {
  InputFeedbackMessageBoxProps,
} from './InputFeedbackMessageBox';
import { Route } from '~/pages/root/util';

export interface ResetPasswordExpiredCodeMessageBoxProps
  extends Omit<InputFeedbackMessageBoxProps, 'body' | 'title'> {}

const ResetPasswordExpiredCodeMessageBox: FC<
  ResetPasswordExpiredCodeMessageBoxProps
> = (props) => {
  const nav = useNavigate();

  return (
    <InputFeedbackMessageBox
      title="Code Expired"
      body={
        <>
          <div className="text-sm leading-5 text-darkMode-gray-light">
            <p className="mb-[6px]">
              Unfortunately that verification code expired. But don't worry—we
              can send you a new one.
            </p>
          </div>
          <ButtonNew
            className="m-auto !mt-12 block text-sm"
            name="actionMode"
            type="button"
            onClick={() => nav(Route.forgotPassword)}
          >
            Resend Verification Code
          </ButtonNew>
        </>
      }
      {...props}
    />
  );
};

export default ResetPasswordExpiredCodeMessageBox;
