import { FC } from 'react';
import InputFeedbackMessageBox, {
  InputFeedbackMessageBoxProps,
} from './InputFeedbackMessageBox';

export interface ResetPasswordReusedPasswordMessageBoxProps
  extends Omit<InputFeedbackMessageBoxProps, 'body' | 'title'> {}

const ResetPasswordReusedPasswordMessageBox: FC<
  ResetPasswordReusedPasswordMessageBoxProps
> = (props) => {
  return (
    <InputFeedbackMessageBox
      title="Dang—that password won't work."
      body={
        <div className="text-sm leading-5 text-darkMode-gray-light">
          <p className="mb-[6px]">
            Your new password needs to be different than any of your previous
            five passwords. What else you got?
          </p>
        </div>
      }
      {...props}
    />
  );
};

export default ResetPasswordReusedPasswordMessageBox;
