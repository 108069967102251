import { menuAppearance } from '@elements-financial/elements';
import { XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { ComponentPropsWithoutRef, FC } from 'react';
import { createBreakpoint } from 'react-use';
import ResetPasswordExpiredCodeMessageBox from './ResetPasswordExpiredCodeMessageBox';
import ResetPasswordReusedPasswordMessageBox from './ResetPasswordReusedPasswordMessageBox';
import WeakPasswordMessageBox from './WeakPasswordMessageBox';

const useBreakPoint = createBreakpoint({
  desktopBreakpoint: 1150,
  mobileBreakpoint: 0,
});

export interface InputFeedbackMessageBoxProps
  extends Pick<ComponentPropsWithoutRef<'dialog'>, 'className' | 'style'> {
  close(): void;
  open: boolean;
  title?: string;
  body?: string | React.ReactNode;
  variant?: InputFeedbackMessageBoxVariant;
  children?: React.ReactNode;
}

/**
 *
 * @deprecated Use the InputFeedbackMessageBox component from the elements package instead.
 */
const InputFeedbackMessageBox: FC<InputFeedbackMessageBoxProps> = ({
  close,
  open,
  className,
  title,
  body,
  children,
  style,
}) => {
  const breakPoint = useBreakPoint();

  return (
    <div className="relative">
      {children}
      <div
        style={{
          left:
            breakPoint === 'mobileBreakpoint'
              ? 'calc(50% - 144px)'
              : 'calc(100% + 22px)',
          bottom:
            breakPoint === 'mobileBreakpoint'
              ? 'calc(100% + 16px)'
              : style?.bottom ?? '-120px',
        }}
        className={clsx(className, 'absolute')}
      >
        <AnimatePresence>
          {open && (
            <motion.dialog
              className={clsx(
                className,
                'dark:shadow-classic shadow-lightModeClassic bottom-0 z-10 w-72 rounded-md bg-lightMode-snow px-6 py-5 dark:bg-darkMode-gray-dropdown'
              )}
              {...menuAppearance}
              open={open}
            >
              <header className="mb-2 flex justify-between">
                <p className="w-[205px] text-[19px] text-darkMode-gray-dark dark:text-white">
                  {title}
                </p>
                <XIcon
                  onClick={close}
                  className="h-6 w-6 cursor-pointer text-primary"
                />
              </header>
              <div className="whitespace-pre-line text-sm leading-5 text-darkMode-gray-medium dark:text-darkMode-gray-light">
                {body}
              </div>
            </motion.dialog>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export type InputFeedbackMessageBoxVariant =
  | 'weakPassword'
  | 'resetPasswordExpiredCode'
  | 'resetPasswordReusedPassword';

export default ({ variant, ...props }: InputFeedbackMessageBoxProps) => {
  switch (variant) {
    case 'weakPassword':
      return <WeakPasswordMessageBox {...props} />;
    case 'resetPasswordExpiredCode':
      return <ResetPasswordExpiredCodeMessageBox {...props} />;
    case 'resetPasswordReusedPassword':
      return <ResetPasswordReusedPasswordMessageBox {...props} />;
    default:
      return <InputFeedbackMessageBox {...props} />;
  }
};
